import store from "@/store";

export default function useResetPassword() {
  // http://localhost:8080/usuario/recuperar-password?token=hola-mundo
  const resetPassword = async (data) => {
    const request = {
      path: "/Login/RecuperarContrasenaToken",
      body: JSON.stringify(data),
    };
    const res = await store.dispatch("app/resetPassword", request);
    if (res.bOk) return;
    throw new Error(`respuesta:${res.cod_resp} - message: ${res.mensaje}`);
  };
  return {
    resetPassword,
  };
}
